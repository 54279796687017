import { ExtLink } from 'components';
import { Container, Box, IconButton, Typography } from '@mui/material';
import Close from '@mui/icons-material/Close';
import monash_logo from 'images/monash-logo.png';

export default function ExplanatoryStatement() {
  return (
    <Container>
      <Box mt={8}>
        <Box display="flex" justifyContent="center">
          <img src={monash_logo} height={50} />
        </Box>

        <Box display="flex" justifyContent="flex-end">
          <IconButton onClick={() => window.close()}>
            <Close />
          </IconButton>
        </Box>
      </Box>

      <Typography variant="h5" align="center" paragraph>
        PARENT EXPLANATORY STATEMENT
      </Typography>

      <Box mb={4}>
        <Typography variant="body1" align="center" paragraph>
          <strong>
            Evaluating the acceptability of an online parenting program (‘Partners in Parenting -Education’) to support
            parents of adolescents who refuse school in the context of anxiety and/or depression
          </strong>
        </Typography>
      </Box>

      <Typography component={'span'} align="left">
        <span>
          <strong>Project ID: 32704</strong>
          <br />
        </span>
        <span>
          <strong>Chief Investigator: </strong> Associate Professor Marie Yap, The Turner Institute for Brain and Mental
          Health Faculty of Medicine, Nursing and Health Sciences, Monash University, Clayton Campus.{' '}
          <strong>P:</strong> <ExtLink href="tel:+61399050723">(03) 9905 0723</ExtLink> <strong>E:</strong>{' '}
          <ExtLink href="mailto:marie.yap@monash.edu">marie.yap@monash.edu</ExtLink>.
        </span>
        <br />
        <span>
          <strong>Co-investigators: </strong> Associate Professor Glenn Melvin, School of Psychology, Faculty of Health,
          Deakin University, Melbourne Burwood Campus. <strong>P:</strong>{' '}
          <ExtLink href="tel:+61392445625">(03) 9244 5625</ExtLink>, <strong>E:</strong>{' '}
          <ExtLink href="mailto:glenn.melvin@deakin.edu.au">glenn.melvin@deakin.edu.au</ExtLink>.
          <br />
          Dr Mairead Cardamone-Breen, The Turner Institute for Brain and Mental Health, Faculty of Medicine, Nursing and
          Health Sciences, Monash University, Clayton Campus. <strong>P:</strong>{' '}
          <ExtLink href="tel:+61399051250">(03) 9905 1250</ExtLink>, <strong>E:</strong>{' '}
          <ExtLink href="mailto:Mairead.cardamone-breen@monash.edu">Mairead.cardamone-breen@monash.edu</ExtLink>.
        </span>
        <br />
        <span>
          <strong>Student Researcher: </strong>Anna Smout, Doctor of Philosophy (Clinical Psychology) candidate The
          Turner Institute for Brain and Mental Health, Faculty of Medicine, Nursing and Health Sciences, Monash
          University, Clayton Campus, <strong>E:</strong>{' '}
          <ExtLink href="mailto:anna.smout@monash.edu">anna.smout@monash.edu</ExtLink>.
        </span>
        <br />
        <span>
          <strong>Program manager:</strong> Kayan Skinner, The Turner Institute for Brain and Mental Health, Faculty of
          Medicine, Nursing and Health Sciences, Monash University, Clayton Campus, <strong>E:</strong>{' '}
          <ExtLink href="mailto:kayan.skinner@monash.edu">kayan.skinner@monash.edu</ExtLink>
        </span>
        <br />
        <br />
        <span>
          <strong>Invitation to participate in research</strong>
        </span>
        <br />
        <span>
          You are invited to take part in research being conducted by Monash University as part of a Doctor of
          Philosophy (Clinical Psychology) research project. Please read this Explanatory Statement in full before
          deciding whether or not to participate. If you would like further information regarding any aspect of the
          project, please contact Anna Smout (
          <ExtLink href="mailto:anna.smout@monash.edu">anna.smout@monash.edu</ExtLink>) or Dr Mairead Cardamone-Breen (
          <ExtLink href="mailto:Mairead.cardamone-breen@monash.edu">Mairead.cardamone-breen@monash.edu</ExtLink>;{' '}
          <strong>P: </strong>
          <ExtLink href="tel:+61399051250">(03) 9905 1250</ExtLink>).
        </span>
        <br />
        <br />
        <span>
          <strong>What does the research involve?</strong>
          <br />
          This study aims to evaluate the acceptability of an online parenting program optimised for school refusal
          (‘Partners in Parenting - Education’/’PiP-Ed’). PiP-Ed is designed to support parents of teenagers who have
          difficulty attending school due to anxiety, depression, or emotional distress (sometimes called{' '}
          <strong>‘school refusal’</strong>, ‘school can’t’, or ‘school phobia’). PiP -Ed aims to increase parent
          confidence to support their teenager by providing personalised feedback about their current parenting,
          equipping them with evidence-based parenting strategies to respond to anxiety, depression, and ‘school
          refusal’.
        </span>
        <br />
        <br />
        <span>
          <strong>What will I be asked to do?</strong>
          <br />
          <ol>
            <li>
              Review this explanatory statement and the inclusion criteria to determine your eligibility for this study.
            </li>
          </ol>
        </span>
        <span>
          <strong>If you are eligible and agree to participate, you will be asked to do the following:</strong>
          <br />
          <ol>
            <li>
              Complete an online registration and consent form. This will include providing some demographic information
              and details about you and your teenager. You’ll also read the study requirements and provide online
              consent.
            </li>
            <li>
              Complete an online survey (30-45mins) about your parenting, your mental health, your teenager’s mental
              health, your teenager’s school attendance, and the impact this has on your family.{' '}
            </li>
            <li>
              Receive individually-tailored feedback about your parenting that highlights areas where you are doing well
              and areas for development. You will be given access to the online parenting program (online modules) to
              support you in making changes. Based on your survey answers, specific modules will be recommended for you
              to complete.
            </li>
            <li>Complete 6-8 weekly online modules (20-35mins) about different topics related to parenting. </li>
            <li>
              You will also have access to 5 additional modules on related topics which you can complete at any stage,
              if you wish.
            </li>
            <li>
              Complete an online survey once you finish the program (20-30 mins), similar to the first survey you
              complete.{' '}
            </li>
          </ol>
        </span>
        <span>
          All parts of this study can be completed at any time or place of convenience to you, as long as there is
          internet access.
        </span>
        <br />
        <br />
        <span>
          <strong>Who is being asked to participate?</strong>
          <br />
          Parents or guardians of a teenager aged 12-18 years who experiences difficulty attending school due to
          anxiety, depression, or emotional distress, are invited to take part in this study. Your teenager will not be
          directly involved in this study.
        </span>
        <br />
        <br />
        <span>
          <strong>In order to take part in the program, you will also need to:</strong>
          <br />
          <ul>
            <li>Be proficient in English</li>
            <li>Live in Australia</li>
            <li>Have regular access to the Internet, a telephone, and email.</li>
          </ul>
          This project is funded by the National Health and Medical Research Centre (NHMRC), Department of Health
          Australia.
        </span>
        <br />
        <br />
        <span>
          <strong>Is there anyone who cannot participate?</strong>
          <p>
            <strong>
              All parents who meet the criteria above are welcome to sign up and access the online program. However,
              please note that we recommend all families to also seek professional support from a health or education
              professional alongside this program.
            </strong>
          </p>
          <p>
            In particular, the program content is not tailored to address the needs of parents of teenagers who
            experience:
          </p>
          <ul>
            <li>
              Serious mental health problems that require urgent care before anxiety, depression, or school refusal can
              be addressed.
            </li>
            <li>Parents of teenagers who live with Autism Spectrum Disorder (ASD) or an intellectual disability.</li>
          </ul>
        </span>
        <em>
          <strong>Please note:</strong> We understand that many young people who experience school refusal, anxiety
          and/or depression are also neurodiverse and/or live with an intellectual disability. Unfortunately, because
          this project is trialling a new program, it is not yet tailored to meet the needs of parents of young people
          with ASD, intellectual disability, and school refusal. We are aware of this limitation and understand the
          importance of extending our resources to support this group, which we plan to do in the future. Please feel
          free to contact the research team if you have any questions or if you would like to register your interest for
          future opportunities.
        </em>
        <br />
        <br />
        <span>
          <strong>Source of funding</strong>
          <br />
          This research project has been funded by Monash University.
        </span>
        <br />
        <br />
        <span>
          <strong> What if I change my mind?</strong>
          <br />
          Participation in this research is completely voluntary. You do not have to agree to participate if you do not
          want to. You have the right to stop your involvement in this project at any time, you just need to tell a
          member of the research team. You do not need to provide a reason why. Changing your mind will not affect the
          relationship you or your teenager have with Monash University, now or in the future. If you leave the project,
          we would still like to use any information you have given us, unless you tell us not to. You can request that
          your data (i.e. survey) be withdrawn prior to the final report being written in March 2023. After this time,
          you will not be able to withdraw your data.
        </span>
        <br />
        <br />
        <span>
          <strong>What are the benefits?</strong>
          <br />
          This project is designed to equip parents with evidence-based parenting strategies to respond to anxiety,
          depression, and ‘school refusal’ in their teenager. You may benefit from having access to educational content
          encouraging positive parenting strategies to identify healthier ways of relating with your adolescent, and to
          implement positive behavioural changes for yourself. Your feedback on the program will help us further improve
          it and increase its benefits for other families in the future.
        </span>
        <br />
        <br />
        <span>
          <strong>Are there any risks?</strong>
          <br />
          The risks of participating in this research are low. It is possible that the program or assessment activities
          bring up issues which are uncomfortable, upsetting, or frustrating for you. If this happens, please let the
          researchers know. They can talk with you about what has upset you and give you information about where else to
          seek support. You are also welcome to withdraw from the study at any time. If you do become upset or
          distressed, please seek support from a family member or friend, or call one of the helplines below:
        </span>
        <br />
        <br />
        <b>
          <i>
            <span style={{ marginLeft: '4rem' }}>Lifeline: 13 11 14 (24 hours a day, 7 days a week)</span>
            <br />
            <span style={{ marginLeft: '4rem' }}>Parentline: 13 22 89 (8am – midnight, 7 days a week)</span>
          </i>
        </b>
        <br />
        <br />
        <span>
          There is also a small risk that your teen experiences a deterioration in their mental health while you are
          engaged in this study. To minimise this risk, we prefer that your teen is in treatment with a mental health
          practitioner or under the care of a medical professional while you are taking part in this study.
          <br />
          <br />
          Although unlikely, there is also a risk to your privacy through breaches of confidentiality, particularly if
          there is a risk of harm to yourself or others that cannot be prevented without breaching confidentiality. If
          we believe that yourself, your child, or someone else is at risk of harm (e.g. child abuse, self-harm), we are
          bound by professional codes of ethics or required by law to take reasonable action to prevent this harm
          occurring, even if it means breaching confidentiality. If this is necessary, we will try to discuss the
          situation with you first.
        </span>
        <br />
        <br />
        <span>
          <strong>Reimbursement</strong>
          <br />
          Upon finishing the PiP -Ed program, you will be invited to complete a follow-up survey. You may also be
          invited to participate in an interview.
        </span>
        <br />
        <br />
        <span>
          <strong>What about privacy?</strong>
          <br />
          We respect your right to privacy. Information collected in this project will only be accessible by researchers
          named on the project. The only exception to this is transcriptions of interviews (if you choose to take part)
          which will be conducted by a reputable and trustworthy third-party transcription service. The service will
          only have access to the interview recording while they are completing the transcription, after which the files
          will be deleted. Third-party services are used as part of the PiP -Ed website and program to capture the data
          entered into the website, and communicate with you via email and SMS. These services all adhere to strict data
          security requirements to ensure the safety and privacy of your data. When we store your personal data, we
          separate this from any identifying information about you, to protect your privacy. All electronic files are
          password protected. Any written reports will only include group data, so you will not be identifiable in any
          way. We will also make non-identifiable questionnaire data (i.e. responses to survey questions, with no other
          information about you) available to other researchers via an online data repository such as Bridges.
        </span>
        <br />
        <br />
        <span>
          <strong>Online program-related data</strong>
          <br />
          The online program will record how many online modules you select and complete, as well as data on your
          interaction with the activities (including your answers to questions).
        </span>
        <br />
        <br />
        <span>
          <strong>Storage and use of data for other purposes</strong>
          <p>
            Electronic data, kept separately from any personal information about you, will be kept indefinitely. All
            data will be stored securely on Monash University servers, password protected, and only accessible by
            approved researchers. De-identified data (i.e. where you cannot be identified) may be used for future
            research, with ethics approval.
          </p>
          <p>
            We will audio and video record all interviews. Interview recordings will be transcribed by a reputable
            third-party service that uses the high standards of the General Data Protection Regulation (GDPR) to ensure
            that data is secure.
          </p>
        </span>
        <span>
          <p>
            <strong>How can I see the results? </strong>
          </p>
          <p>
            A summary of results will be available in 2023. If you would like a copy, please contact Anna Smout at{' '}
            <ExtLink href="mailto:anna.smout@monash.edu">anna.smout@monash.edu</ExtLink>. We also aim to have the
            results presented at conferences and published in scientific journals.
          </p>
        </span>
        <span>
          <p>
            <strong>What if I have any complaints or concerns? </strong>
          </p>
          <p>
            This project has been approved by the Monash University Human Research Ethics Committee. Should you have any
            concerns or complaints about the conduct of the project, you are welcome to contact the Executive Officer,
            Monash University Human Research Ethics Committee (MUHREC):
          </p>
          <Box mt={2}>
            Executive Officer
            <br />
            Monash University Human Research Ethics Committee (MUHREC)
            <br />
            Room 111, Chancellery Building D,
            <br />
            26 Sports Walk, Clayton Campus
            <br />
            Research Office
            <br />
            Monash University VIC 3800
            <br />
            <strong>P:</strong> <ExtLink href="tel: +61399052052">+61 3 9905 2052</ExtLink>
            <br />
            <strong>E:</strong> <ExtLink href="mailto: muhrec@monash.edu">muhrec@monash.edu</ExtLink>
            <br />
            <strong>F:</strong> <ExtLink href="tel: +61399053831">+61 3 9905 3831</ExtLink>
            <br />
          </Box>
        </span>
        <br />
        <span>Thank you for taking the time to read this Explanatory Statement,</span>
        <br />
        <br />
        {/* add signature  */}
        <strong>Associate Professor Marie Yap</strong>
        <br />
        Head, Parenting and Youth Mental Health Group
        <br />
        Turner Institute for Brain and Mental Health, School of Psychological Sciences
        <br />
        Monash University
        <br />
      </Typography>

      <Box pb={8} />
    </Container>
  );
}
